import React from 'react';

const WebGLApp = () => {

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <iframe
        title="WebGL App"
        src="/webgl-build/index.html"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
};

export default WebGLApp;