import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import FormOne from '../../component/contact/FormOne';

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {

    return (
        <footer id='footerArea' className={`footer-area ${parentClass}`}>
            <div className="container">

                <div className="footer-main mb-4">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title mt-4">Any Problems? Contact Us By Filling The Form</h2>
                                    {/* <p>Fusce varius, dolor tempor interdum tristique, dui urna bib endum magna, ut ullamcorper purus</p> */}

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="contact-form-box">
                                {/* <h3 className="title">Get a free Keystroke quote now</h3> */}
                                <FormOne />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://vgyani.com/">Vgyani Edutainment Solutions Pvt. Ltd.</a></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/refund-and-cancellation-policy"}>Refund & Cancellation Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-and-conditions"}>Terms & Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;